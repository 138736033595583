/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
import React from 'react';
import emotionReset from 'emotion-reset';
import {
  Global,
  css,
  useTheme,
  CacheProvider,
} from '@emotion/react';
import { cache } from '@emotion/css';
import { cosmicpuma } from '@nonsequitur/component-library';

import { AuthProvider } from './src/components/contexts/Auth';

const { DesignSystemProvider } = cosmicpuma;

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <DesignSystemProvider>
      <Globals />
      <CacheProvider value={cache}>
        {element}
      </CacheProvider>
    </DesignSystemProvider>
  </AuthProvider>
);

const Globals = () => {
  const theme = useTheme();

  return (
    <Global styles={css`
        ${emotionReset}

        *, *::after, *::before {
          box-sizing: border-box;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
        }

        html,
        body {
          font-family: ${theme.typographyBody1.fontFamily};
          font-size: ${theme.typographyBody1.fontSize};
          font-weight: ${theme.typographyBody1.fontWeight};
          line-height: ${theme.typographyBody1.lineHeight};
          font-style: ${theme.typographyBody1.fontStyle};
          color: ${theme.colorText};
          background-color: ${theme.colorBackgroundSecondary};
        }

        h1 {
          font-family: ${theme.typographyHeader1.fontFamily};
          font-size: ${theme.typographyHeader1.fontSize};
          font-weight: ${theme.typographyHeader1.fontWeight};
          line-height: ${theme.typographyHeader1.lineHeight};
          font-style: ${theme.typographyHeader1.fontStyle};
        }

        h2 {
          font-family: ${theme.typographyHeader2.fontFamily};
          font-size: ${theme.typographyHeader2.fontSize};
          font-weight: ${theme.typographyHeader2.fontWeight};
          line-height: ${theme.typographyHeader2.lineHeight};
          font-style: ${theme.typographyHeader2.fontStyle};
        }

        h3 {
          font-family: ${theme.typographyHeader3.fontFamily};
          font-size: ${theme.typographyHeader3.fontSize};
          font-weight: ${theme.typographyHeader3.fontWeight};
          line-height: ${theme.typographyHeader3.lineHeight};
          font-style: ${theme.typographyHeader3.fontStyle};
        }

        p {
          font-family: ${theme.typographyBody2.fontFamily};
          font-size: ${theme.typographyBody2.fontSize};
          font-weight: ${theme.typographyBody2.fontWeight};
          line-height: ${theme.typographyBody2.lineHeight};
          font-style: ${theme.typographyBody2.fontStyle};
        }

        blockquote {
          font-family: ${theme.typographyBody3.fontFamily};
          font-size: ${theme.typographyBody3.fontSize};
          font-weight: ${theme.typographyBody3.fontWeight};
          line-height: ${theme.typographyBody3.lineHeight};
          font-style: ${theme.typographyBody3.fontStyle};
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 50px;
          padding-left: 15px;
          border-left: 3px solid #ccc;
        }

        strong {
          font-weight: 700;
        }

        em {
          font-style: italic;
        }

        u {
          text-decoration: underline;
        }
      `}
    />
  );
};
